<template>
  <div class="book-user border-radius-6 box-shadow-light-grey padding-20">
    <div class="operation padding-bottom-20">
      <el-button @click="refresh" class="margin-right-twentyFour">
        刷新数据</el-button
      >
      <el-button type="primary" @click="checkFilter" style="margin-left: 0"
        >高级筛选</el-button
      >
    </div>

    <div class="custom-table" v-loading="loading">
      <el-table
        :data="dataList"
        style="width: 100%"
        height="calc(100vh - 300px)"
        ref="dataTable"
      >
        <el-table-column
          label="用户Id"
          prop="userId"
          min-width="150"
        ></el-table-column>
        <el-table-column label="性别" prop="sex"></el-table-column>
        <el-table-column
          label="昵称"
          prop="nickname"
          min-width="150"
        ></el-table-column>
        <el-table-column
          label="总书币"
          prop="totalKandian"
          min-width="100"
        ></el-table-column>
        <el-table-column
          label="剩余书币"
          prop="kandian"
          min-width="100"
        ></el-table-column>
        <el-table-column
          label="充值金额"
          prop="rechargeAmount"
        ></el-table-column>
        <el-table-column label="充值次数" prop="rechargeNum"></el-table-column>
        <el-table-column
          label="最近一次充值时间"
          prop="lastRechargeTime"
          min-width="200"
        ></el-table-column>
        <el-table-column label="openid" min-width="200">
          <template slot-scope="scope">
            <div class="line-overflow">
              {{ scope.row.openid }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="公众号"
          prop="authorizerNickname"
        ></el-table-column>
        <el-table-column label="省份" prop="province"></el-table-column>
        <el-table-column label="城市" prop="city"></el-table-column>
        <el-table-column
          label="关注时间"
          prop="subscribeTime"
          min-width="200"
        ></el-table-column>
        <el-table-column
          label="注册时间"
          prop="registertime"
          min-width="200"
        ></el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 20px; text-align: right"
        background
        @current-change="handlePageChange"
        :current-page="page"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-drawer
      title="筛选条件"
      size="30%"
      :visible.sync="showDrawer"
      :before-close="handleClose"
    >
      <div class="custom-filter padding-20">
        <div class="filter-list">
          <div class="filter-list-item">
            <span>平台</span>
            <el-select v-model="platform" placeholder="请选择平台">
              <el-option
                :label="item.name"
                :value="item.id"
                :key="item.id"
                v-for="item in platformList"
              ></el-option>
            </el-select>
          </div>
          <div class="filter-list-item">
            <span>公众号Id</span>
            <el-select
              v-model="accountChannelId"
              placeholder="请选择公众号"
              @change="handleOfficialChange"
              filterable
            >
              <el-option
                v-for="item in channelList"
                :key="item.id"
                :label="item.channelName"
                :value="item.id"
              >
                <span style="float: left">{{ item.channelName }}</span>
                <span style="float: right; margin-left: 15px; font-size: 13px"
                  >{{ item.platformStr }} - {{ item.uname }}</span
                >
              </el-option>
            </el-select>
          </div>
          <div class="filter-list-item">
            <span>用户Id</span>
            <el-select
              v-model="userId"
              filterable
              clearable
              reserve-keyword
              placeholder="用户"
            >
              <el-option
                v-for="item in userList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="filter-list-item">
            <span>性别</span>
            <el-radio-group v-model="sex">
              <el-radio label="未知">所有</el-radio>
              <el-radio label="男性">男</el-radio>
              <el-radio label="女性">女</el-radio>
            </el-radio-group>
          </div>
          <div class="filter-list-item">
            <span>昵称</span>
            <el-input
              v-model="nickname"
              type="text"
              placeholder="请输入昵称"
            ></el-input>
          </div>
          <div class="filter-list-item">
            <span>openid</span>
            <el-input
              v-model="openid"
              placeholder="请输入openid"
              style="width: 200px; margin-right: 10px"
            ></el-input>
          </div>
          <div class="filter-list-item">
            <span>省份</span>
            <el-input
              type="text"
              placeholder="请输入省份"
              v-model="province"
            ></el-input>
          </div>
          <div class="filter-list-item">
            <span>城市</span>
            <el-input v-model="city" placeholder="请输入城市"></el-input>
          </div>
          <div class="filter-list-item">
            <span>最小剩余书币</span>
            <el-input
              v-model="minKandian"
              placeholder="最小剩余书币"
            ></el-input>
          </div>
          <div class="filter-list-item">
            <span>最大剩余书币</span>
            <el-input
              v-model="maxKandian"
              placeholder="最大剩余书币"
            ></el-input>
          </div>
          <div class="filter-list-item">
            <span>总币数</span>
            <el-input
              v-model="minTotalKandian"
              placeholder="最小总数币"
            ></el-input>
          </div>
          <div class="filter-list-item">
            <span>最大总币数</span>
            <el-input
              v-model="maxTotalKandian"
              placeholder="最大总数币"
            ></el-input>
          </div>

          <div class="filter-list-item">
            <span>最近一次充值时间</span>
            <el-date-picker
              style="width: 200px"
              v-model="timeRange1"
              type="datetimerange"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
            >
            </el-date-picker>
          </div>
          <div class="filter-list-item">
            <span>最小充值次数</span>
            <el-input
              v-model="minRechargeNum"
              placeholder="最小充值次数"
            ></el-input>
          </div>
          <div class="filter-list-item">
            <span>最大充值次数</span>
            <el-input
              v-model="maxRechargeNum"
              placeholder="最大充值次数"
            ></el-input>
          </div>
          <div class="filter-list-item">
            <span>最小充值金额</span>
            <el-input
              v-model="minRechargeAmount"
              placeholder="最小充值金额"
            ></el-input>
          </div>
          <div class="filter-list-item">
            <span>最大充值金额</span>
            <el-input
              v-model="maxRechargeAmount"
              placeholder="最大充值次数"
            ></el-input>
          </div>
          <div class="filter-list-item">
            <span>用户注册时间</span>
            <el-date-picker
              style="width: 200px"
              v-model="timeRange2"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              :default-time="['00:00:00', '23:59:59']"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="button-list">
          <el-button type="primary" @click="getFilterData">确定</el-button>
          <el-button type="info" @click="handleClose">取消</el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { getBookPlatformUserList } from "@/api/user.js";

export default {
  name: "BookUser",
  data() {
    return {
      dataList: [],
      loading: false,
      page: 1,
      pageSize: 15,
      platform: null, // 平台
      accountChannelId: null, // 公众号Id
      userId: null, // 用户Id
      nickname: null, // 昵称
      openid: null, // openid
      sex: null, // 性别
      province: null, // 省份
      city: null, // 城市
      minKandian: null, // 最小剩余书币
      maxKandian: null, // 最大剩余书币
      minTotalKandian: null, // 最小总数币
      maxTotalKandian: null, // 最大总数币
      minLastRechargeTime: null, // 最小最近一次充值时间
      maxLastRechargeTime: null, // 最大最近一次充值时间
      minRechargeNum: null, // 最小充值次数
      maxRechargeNum: null, // 最大充值次数
      minRechargeAmount: null, // 最小充值金额
      maxRechargeAmount: null, // 最大充值金额
      startRegisterTime: null, // 用户注册开始
      endRegisterTime: null, // 用户注册结束
      showDrawer: false,
      timeRange1: [],
      timeRange2: [],
      total: 0,
      platformList: [
        {
          id: 0,
          name: "掌中云",
        },
        {
          id: 1,
          name: "阅文",
        },
        {
          id: 2,
          name: "追书云",
        },
        {
          id: 3,
          name: "阳光书城",
        },
        {
          id: 4,
          name: "悠书阁",
        },
        {
          id: 5,
          name: "网易",
        },
        {
          id: 6,
          name: "七悦",
        },
      ],
    };
  },
  computed: {
    channelList() {
      const { officialList } = this;
      return officialList.filter(
        (item) =>
          (item.platform === 1 ||
            item.platform === 0 ||
            item.platform === 3 ||
            item.platform === 4 ||
            item.platform === 6) &&
          item.status === 1
      );
    },
    ...mapGetters(["officialList", "userList"]),
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      this.loading = true;
      getBookPlatformUserList({
        page: this.page,
        pageSize: this.pageSize,
        platform: this.platform,
        accountChannelId: this.accountChannelId,
        userId: this.userId,
        nickname: this.nickname,
        openid: this.openid,
        sex: this.sex,
        province: this.province,
        city: this.city,
        minKandian: this.minKandian,
        maxKandian: this.maxKandian,
        minTotalKandian: this.minTotalKandian,
        maxTotalKandian: this.maxTotalKandian,
        minLastRechargeTime: this.timeRange1 ? this.timeRange1[0] : null,
        maxLastRechargeTime: this.timeRange1 ? this.timeRange1[1] : null,
        minRechargeNum: this.minRechargeNum,
        maxRechargeNum: this.maxRechargeNum,
        minRechargeAmount: this.minRechargeAmount,
        maxRechargeAmount: this.maxRechargeAmount,
        startRegisterTime: this.timeRange2 ? this.timeRange2[0] : null,
        endRegisterTime: this.timeRange2 ? this.timeRange2[1] : null,
      })
        .then((res) => {
          this.dataList = res.list;
          this.total = res.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handlePageChange(page) {
      this.page = page;
      this.initData();
    },
    refresh() {
      this.initData();
    },
    getFilterData() {
      this.showDrawer = false;
      this.initData();
    },
    handleOfficialChange() {},
    handleClose() {
      this.showDrawer = false;
      this.resetData();
    },
    checkFilter() {
      this.showDrawer = true;
    },
    resetData() {
      this.page = 1;
      this.platform = null;
      this.accountChannelId = null;
      this.userId = null;
      this.nickname = null;
      this.openid = null;
      this.sex = null;
      this.province = null;
      this.city = null;
      this.minKandian = null;
      this.maxKandian = null;
      this.minTotalKandian = null;
      this.maxTotalKandian = null;
      this.minLastRechargeTime = null;
      this.maxLastRechargeTime = null;
      this.minRechargeNum = null;
      this.maxRechargeNum = null;
      this.minRechargeAmount = null;
      this.maxRechargeAmount = null;
      this.startRegisterTime = null;
      this.endRegisterTime = null;
      this.timeRange1 = null;
      this.timeRange2 = null;
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-drawer {
  /deep/ .el-drawer__header {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
}
.el-input {
  width: 200px;
}
.book-user {
  background-color: #fff;

  .custom-table {
    // width: calc( 100vw - 650px);
  }
}

.custom-filter {
  .filter-list {
    .filter-list-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 50px;
      > span {
        width: 150px;
      }
      > :nth-child(2) {
        flex-grow: 1;
      }
    }
  }
  .button-list {
     text-align: center;
  }
}
</style>
